import React, { Fragment, useEffect, useState } from 'react';
import './HomePage.scss';
import { Link } from "react-router-dom";
import Card from '../components/card/Card';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined } from '@mui/icons-material';
import ContactUs from '../components/contact-us-form/ContactUsForm';
import colors from '../_variables.module.scss';
import PageLayout from '../components/page-layout/PageLayout';
import Logo from '../logo_white_text.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import illustration01 from '../images/home/illustration01.svg';
import illustration02 from '../images/home/illustration02.svg';
import illustration03 from '../images/home/illustration03.svg';
import illustration04 from '../images/home/illustration04.svg';
import illustration05 from '../images/home/illustration05.svg';
import illustration06 from '../images/home/illustration06.svg';
import illustration07 from '../images/home/illustration07.svg';
import illustration08 from '../images/home/illustration08.svg';
import whatsappIntegration from '../images/features/WhatsAppIntegration2.svg';
import experiments from '../images/features/Experiments2.svg';
import artificialIntelligence from '../images/features/ArtificialIntelligence.svg';
import leadQualification from '../images/features/LeadQualification.svg';
import documentManagement from '../images/features/DocumentManagement.svg';
import calendarManagement from '../images/features/CalendarManagement.svg';
import leadWorkflow from '../images/features/LeadWorkflow.svg';
import businessDashboard from '../images/features/BusinessDashboard.svg';


const HomePage = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [featureAccordionOpen, setFeatureAccordionOpen] = useState(false);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };

    const CustomRightArrow = ({ onClick }) => {
        return <button className="grid-carousel-button grid-carousel-right-button" type="button" onClick={() => onClick()} >
            <ArrowForwardIcon />
        </button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className="grid-carousel-button grid-carousel-left-button" type="button" onClick={() => onClick()} >
            <ArrowBackIcon />
        </button>;
    };


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <Fragment>
            <PageLayout title={"Accelerare | Home"} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='home-page'>
                    <div className='banner-component'>
                        {<div className='banner-title'>
                            <div className='title'>
                                <img src={Logo} width={"100px"} height={"100px"} alt="logo" />
                            </div>
                            <div className='list'>
                                <ul>
                                    <li>Our digital business platform <strong className="lighter-primary">seamlessly</strong> integrates various <strong className="lighter-primary">digital technologies</strong> to create novel <strong className="lighter-primary">business assets</strong> and essential <strong className="lighter-primary">capabilities for impactful</strong> digital <strong className="lighter-primary">transformation</strong>.</li>
                                    <li>It goes beyond <strong className="lighter-primary">traditional</strong> technology platforms by <strong className="lighter-primary">harnessing</strong> technologies like Artificial Intelligence <strong className="lighter-primary">(AI)</strong>, The Internet of Things <strong className="lighter-primary">(IoT)</strong>, Machine Learning <strong className="lighter-primary">(ML)</strong>, and Large Language models <strong className="lighter-primary">(LLM)</strong> to enable smart, data-driven <strong className="lighter-primary">decision-making</strong> and drive <strong className="lighter-primary">business success</strong> in today's dynamic landscape.</li>
                                    <li>Explore our <strong className="lighter-primary">offerings</strong> to unlock <strong className="lighter-primary">key</strong> business <strong className="lighter-primary">capabilities</strong> and fuel <strong className="lighter-primary">growth</strong>.</li>
                                </ul>
                                <div className="contact-btn">
                                    <button className="btn" onClick={() => setDialogOpen(true)}>Contact Us</button>
                                </div>
                                <Dialog open={dialogOpen} fullWidth >
                                    <DialogTitle sx={{ fontSize: '30px', fontWeight: '700', paddingLeft: '50px' }}>
                                        <div className='d-flex justify-content-between'>
                                            Contact Me
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => setDialogOpen(false)}
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        </div>
                                    </DialogTitle>
                                    <ContactUs />
                                </Dialog>
                                {/*<Link className="btn btn-accent" aria-current="page" to="/enquire-now" onClick={() => {  }}>GET A QUOTE</Link>*/}
                            </div>
                        </div>}
                    </div>
                    <div className='first-section container '>
                        <div className="heading">
                            What we do
                        </div>
                        <div className='grid'>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration01}
                                        content={"Integrate diverse digitalisation technologies into a unified, cohesive solution"}
                                        backgroundColor={colors.greyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration02}
                                        content={"Intellectually manage this integration to enable the creation and growth of valuable digital business resources."}
                                        backgroundColor={colors.greyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration03}
                                        content={"Provide digital business resources that are integral facilitators of true digital transformation within business models and processes."}
                                        backgroundColor={colors.greyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='second-section'>
                        <div className="heading">
                            Domains
                        </div>
                        <div className='sub-heading'>
                            The domains we focus on to enable, improve and maintain an effective digital business platform.
                        </div>
                        <div className='grid-carousel'>
                            <Carousel responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} draggable={true} swipeable={true}>
                                <Link to="/domains/lead-management">
                                    <Card
                                        heading={"Lead Management"}
                                        icon={illustration04}
                                        content={"The systematic process of acquiring, tracking, and nurturing potential customers from the initial point of interest to conversion."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/inventory-management">
                                    <Card
                                        heading={"Inventory Management"}
                                        icon={illustration05}
                                        content={"A full analysis of your business platform to deliver user experience driven design solutions."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>

                                <Link to="/domains/customer-relations">
                                    <Card
                                        heading={"Customer Relations"}
                                        icon={illustration06}
                                        content={"Guidance on the optimisation of customer interactions throughout their journey."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/listing-management">
                                    <Card
                                        heading={"Listing Management"}
                                        icon={illustration07}
                                        content={"Managing and maintaining the various online listings or profiles to ensure accuracy and consistency."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/website-management">
                                    <Card
                                        heading={"Website Management"}
                                        icon={illustration08}
                                        content={"Overseeing and maintaining a website to ensure its optimal functionality, security, and performance."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                            </Carousel>
                        </div>
                        <div className='section-divider'></div>
                        <div className="heading clickable" onClick={() => setFeatureAccordionOpen(!featureAccordionOpen)}>
                            Features
                            {!featureAccordionOpen ?
                                <ArrowDropDownIcon className='drop-down-icon' style={{ color: colors.navy }} />
                                :
                                <ArrowDropUpIcon className='drop-down-icon' style={{ color: colors.navy }} />}
                        </div>
                        <div className='sub-heading'>
                            The distinct attributes inherent to our offered products.
                        </div>
                        {featureAccordionOpen &&
                            <div className='accordion-list'>
                                <div className='grid'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/experiments">
                                                <Card
                                                    heading={"Experiments"}
                                                    icon={experiments}
                                                    content={"Data-driven insights, helping businesses make informed decisions to optimise strategies and achieve improved results."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/whatsapp-integration">
                                                <Card
                                                    heading={"WhatsApp Integration"}
                                                    icon={whatsappIntegration}
                                                    content={"Empowers businesses with a versatile and powerful tool for communication, customer engagement, and marketing."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/artificial-intelligence">
                                                <Card
                                                    heading={"Artificial Intelligence"}
                                                    icon={artificialIntelligence}
                                                    content={"Can lead to increased efficiency, better decision-making, improved customer experiences, and a competitive advantage."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/lead-qualification">
                                                <Card
                                                    heading={"Lead Qualification"}
                                                    icon={leadQualification}
                                                    content={"Evaluating and categorising potential customers based on their likelihood to convert into paying customers."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/document-management">
                                                <Card
                                                    heading={"Document Management"}
                                                    icon={documentManagement}
                                                    content={"Efficiently create, organise, store, retrieve, and manage documents in a systematic and secure manner."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/calendar-management">
                                                <Card
                                                    heading={"Calendar Management"}
                                                    icon={calendarManagement}
                                                    content={"Retrospective tracking of changes implemented"}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/lead-workflow">
                                                <Card
                                                    heading={"Lead Workflow, Escalation & Tasks"}
                                                    icon={leadWorkflow}
                                                    content={"A systematic process through which tasks are set to reach a goal and escalating issues to foster more efficient resolution thereof."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/business-overview">
                                                <Card
                                                    heading={"Business Overview Dashboard"}
                                                    icon={businessDashboard}
                                                    content={"Visual representation of key performance indicators and critical metrics that provide a comprehensive snapshot of performance."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default HomePage;