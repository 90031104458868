import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactUsForm.scss';

import { render } from '@react-email/render';
import { Email } from '../sendgrid/Email';

import SendgridService from '../../services/SendgridService';
import { CloseOutlined } from '@mui/icons-material';

const ContactUs = ({ children, reason = '' }) => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);

    const [nameAndSurname, setNameAndSurname] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [reasonForEnquiry, setReasonForEnquiry] = useState(reason);
    const [yourMessage, setYourMessage] = useState('');

    const [nameAndSurnameValid, setNameAndSurnameValid] = useState(null);
    const [emailAddressValid, setEmailAddressValid] = useState(null);
    const [mobileNumberValid, setMobileNumberValid] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [reasonForEnquiryValid, setReasonForEnquiryValid] = useState(reason !== '' ? true : null);
    const [yourMessageValid, setYourMessageValid] = useState(null);

    const validateForm = () => {
        if (nameAndSurname.trim() !== '') {
            setNameAndSurnameValid(true);
        } else {
            setNameAndSurnameValid(false);
        }

        if (/^\S+@\S+\.\S+$/.test(emailAddress.trim())) {
            setEmailAddressValid(true);
        } else {
            setEmailAddressValid(false);
        }

        if (/^(\+27|0)([6-8][0-9]{8})$/.test(mobileNumber.trim())) {
            setMobileNumberValid(true);
        } else {
            setMobileNumberValid(false);
        }

        /*if (reasonForEnquiry !== '') {
            setReasonForEnquiryValid(true);
        } else {
            setReasonForEnquiryValid(false);
        }*/

        if (yourMessage.trim() !== '') {
            setYourMessageValid(true);
        } else {
            setYourMessageValid(false);
        }

        return nameAndSurnameValid &&
            emailAddressValid &&
            mobileNumberValid &&
            //reasonForEnquiryValid &&
            yourMessageValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const emailHtmlTemplate = render(<Email url={window.location.host} nameAndSurname={nameAndSurname} emailAddress={emailAddress} mobileNumber={mobileNumber} reasonForEnquiry={reasonForEnquiry} yourMessage={yourMessage} />);
        const options = {
            from: `noreply@accelerare.tech`,
            to: `${process.env.REACT_APP_EMAIL_ADDRESS}`,
            subject: reasonForEnquiry,
            text: emailAddress + ": " + yourMessage,
            html: emailHtmlTemplate,
        };

        const services = new SendgridService();
        await services.sendEmail(options);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setNameAndSurname('');
            setEmailAddress('');
            setMobileNumber('');
            setReasonForEnquiry(reason);
            setYourMessage('');
            setNameAndSurnameValid(null);
            setEmailAddressValid(null);
            setMobileNumberValid(null);
            setReasonForEnquiryValid(reason !== '' ? true : null);
            setYourMessageValid(null);
        }, 5000);
    }

    return (
        <div className={`contact-us-form`}>
            <div className='container'>
                {!success ? <div className='row justify-content-center'>
                    <div className='px-5'>
                        <form className='needs-validation' onSubmit={(e) => onSubmit(e)} noValidate>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="Name & Surname" className={`form-control ${nameAndSurnameValid === null ? '' : nameAndSurnameValid ? 'is-valid' : 'is-invalid'}`} id="NameAndSurname" value={nameAndSurname} onChange={(e) => { setNameAndSurnameValid(e.target.value.trim() !== ''); setNameAndSurname(e.target.value) }} />
                                <label htmlFor="NameAndSurname" className="form-label">Name & Surname</label>
                                <div className="invalid-feedback">
                                    Please provide your name and surname.
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="name@example.com" className={`form-control ${emailAddressValid === null ? '' : emailAddressValid ? 'is-valid' : 'is-invalid'}`} id="EmailAddress" value={emailAddress} onChange={(e) => { setEmailAddressValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmailAddress(e.target.value) }} />
                                <label htmlFor="EmailAddress" className="form-label">Email Address</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${emailAddress === '' ? 'your' : 'a valid'} email address.`}.
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="+27821234567" maxLength={12} className={`form-control ${mobileNumberValid === null ? '' : mobileNumberValid ? 'is-valid' : 'is-invalid'}`} id="MobileNumber" value={mobileNumber} onChange={(e) => { setMobileNumberValid(/^(\+27|0)([6-8][0-9]{8})$/.test(e.target.value.trim())); setMobileNumber(e.target.value) }} />
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${mobileNumber === '' ? 'your' : 'a valid'} mobile number.`}
                                </div>
                            </div>
                            {/*<div className="mb-3 form-floating">
                                <select placeholder="Reason for enquiry" className={`form-control ${reasonForEnquiryValid === null ? '' : reasonForEnquiryValid ? 'is-valid' : 'is-invalid'}`} id="ReasonForEnquiry" value={reasonForEnquiry} onChange={(e) => { setReasonForEnquiryValid(e.target.value.trim() !== ''); setReasonForEnquiry(e.target.value) }} disabled={reason !== ''} >
                                    <option value="">Select a reason</option>
                                    <option value="Contact me">Contact me</option>
                                    <option value="Enquiry about a product/service">Enquiry about a product/service</option>
                                </select>
                                <label htmlFor="ReasonForEnquiry">Reason for enquiry</label>
                                <div className="invalid-feedback">
                                    Please select a reason for your enquiry.
                                </div>
    </div>*/}
                            <div className="mb-3 form-floating">
                                <textarea placeholder="Your Message" className={`form-control form-textarea ${yourMessageValid === null ? '' : yourMessageValid ? 'is-valid' : 'is-invalid'}`} id="YourMessage" value={yourMessage} onChange={(e) => { setYourMessageValid(e.target.value.trim() !== ""); setYourMessage(e.target.value) }}></textarea>
                                <label htmlFor="YourMessage">Your Message</label>
                            </div>
                            <button type="submit" className="btn btn-contact ">Contact Me</button>
                        </form>

                    </div>
                </div> :
                    <div className='row justify-content-center'>
                        <div className='col-sm-10 col-md-8 col-lg-6 col-12'>
                            <div className='alert alert-success' role='alert'>
                                <h4 className='alert-heading'>Well done!</h4>
                                <p>Your message has been sent successfully.</p>
                                <hr />
                                <p className='mb-0'>Thank you for contacting us.</p>
                            </div>
                        </div>
                    </div>}
                {children}
            </div>
        </div>
    );
}

export default ContactUs;